export const breadcrumbList = [
  {
    name: '商品管理',
    path: ''
  },
  {
    name: '卡项列表',
    path: '/main/card/list'
  },
  {
    name: '添加卡项',
    path: ''
  }
]

export const addFormRules = {
  kindId: [{ required: true, message: '请选择产品类型', trigger: 'blur' }],
  cardName: [{ required: true, message: '请输入卡面名称', trigger: 'blur' }],
  handselList2: [{ required: true, message: '选择购卡项目', trigger: 'blur' }],
  // cardAlias: [{ required: true, message: '请输入卡面说明', trigger: 'blur' }],
  timeLimitFlag: [{ required: true, message: '请选择时效', trigger: 'blur' }],
  salePrice: [{ required: true, message: '请输入售价', trigger: 'blur' }],
  expireUseFlag:[{ required: true, message: '请选择过期后是否可用', trigger: 'blur' }],
  buyerRoleFlag:[{ required: true, message: '请选择购买限制', trigger: 'blur' }],
  displayApplet:[{ required: true, message: '请选择是否小程序/网店展示', trigger: 'change' }]
}
