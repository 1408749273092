<template>
  <div class="add-staff-dialog">
    <page-dialog
      :width="50"
      @changeDialogVisible="changeDialogVisible"
      :dialogVisible="dialogVisible"
      title="赠送权益"
      :isShowFooter="true"
      @handleSaveClick="handleSaveClick"
    >
      <div class="top-form">
        <el-select
          clearable
          @change="categoryChange"
          v-model="queryInfo.kind"
          class="serveCategory"
          placeholder="请选择分类"
          size="small"
        >
          <el-option
            v-for="item in serveCategoryList"
            :key="item.kindId"
            :label="item.kindName"
            :value="item.kindId"
          >
          </el-option>
        </el-select>
        <el-input
          size="small"
          clearable
          v-model="queryInfo.keyword"
          placeholder="输入关键词"
          @clear="handleSearchClick"
        />
        <el-button size="small" @click="handleSearchClick" type="primary" plain
          >搜索</el-button
        >
      </div>

      <div class="content">
        <div class="left-item">
          <div class="titls">
            <div>服务</div>
            <div>价格</div>
            <div>时长</div>
          </div>
          <div class="staff-list">
            <el-scrollbar height="320px">
              <div class="serve-list">
                <template :key="item" v-for="item in serveList">
                  <div @click="handleServeClick(item, 'serve')" class="item">
                    <div class="left">
                      <div class="name">{{ item.serveName }}</div>
                    </div>
                    <div class="right">
                      <el-checkbox-group
                        v-if="item.paraList.length > 0"
                        v-model="checkGroupServeList"
                      >
                        <template :key="ele" v-for="ele in item.paraList">
                          <div @click="handleCheckbox(ele, 'serve')">
                            <el-checkbox
                              @change="handleCheckBoxChange($event, 'serve')"
                              :label="ele.paraId"
                            >
                              <div class="checkbox">
                                <div class="salePrice">¥{{ ele.salePrice / 100 }}</div>
                                <div class="serveLength">
                                  {{ ele.serveTimeLength }}分钟
                                </div>
                              </div>
                            </el-checkbox>
                          </div>
                        </template>
                      </el-checkbox-group>
                    </div>
                  </div>
                </template>
              </div>
            </el-scrollbar>
          </div>
          <!-- <el-tabs type="card">
            <el-tab-pane label="服务">

            </el-tab-pane>
            <el-tab-pane label="商品">
              <div class="titls">
                <div>服务</div>
                <div>价格</div>
              </div>
              <div class="staff-list goods-list">
                <el-scrollbar height="250px">
                  <div class="serve-list">
                    <template :key="item" v-for="item in goodsInfoList">
                      <div @click="handleServeClick(item)" class="item">
                        <div class="left">
                          <div class="name">{{ item.goodName }}</div>
                        </div>
                        <div class="right">
                          <el-checkbox-group
                            v-if="item.formatList.length > 0"
                            v-model="checkGroupGoodsList"
                          >
                            <template :key="ele" v-for="ele in item.formatList">
                              <div @click="handleCheckbox(ele)">
                                <el-checkbox
                                  @change="handleCheckBoxChange($event, 'goods')"
                                  :label="ele.paraId"
                                >
                                  <div class="checkbox">
                                    <div class="salePrice">
                                      ¥{{ ele.salePrice / 100 }}
                                    </div>
                                  </div>
                                </el-checkbox>
                              </div>
                            </template>
                          </el-checkbox-group>
                        </div>
                      </div>
                    </template>
                  </div>
                </el-scrollbar>
              </div>
            </el-tab-pane>
          </el-tabs> -->

          <!-- <el-pagination
            v-model:currentPage="currentPage.page"
            v-model:page-size="currentPage.pageSize"
            :page-sizes="[10, 20, 30, 40]"
            :small="true"
            layout=" sizes, prev, pager, next"
            :total="serveCount"
            :pager-count="3"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          /> -->
        </div>
        <div class="right-item">
          <div class="title">
            <div>已选服务</div>
            <div>售价</div>
            <div class="num">可用次数</div>
            <div>操作</div>
          </div>
          <el-scrollbar height="320px">
            <div class="opt-staff-list">
              <template :key="item" v-for="item in mainCheckHandselList">
                <template :key="ele" v-for="ele in item.paraIdList">
                  <opt-serve
                    v-if="item.serveName"
                    @removeServeClick="removeServeClick"
                    :serveName="item.serveName"
                    :ruleForm="ele"
                  ></opt-serve>
                  <opt-goods
                    v-if="item.goodName"
                    @removeGoodsClick="removeGoodsClick"
                    :goodName="item.goodName"
                    :ruleForm="ele"
                  ></opt-goods>
                </template>
              </template>
            </div>
          </el-scrollbar>
        </div>
      </div>
    </page-dialog>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, watch, computed, onUnmounted } from 'vue'
import PageDialog from '@/components/page-dialog/page-dialog.vue'
import { useStore } from 'vuex'
import { COMPANY_ID } from '@/common/constant'
import { useState } from '@/hooks'
import { UPLOAD_BASE_URL } from '@/common/constant'
import { nextTick } from 'process'
import optGoods from './cpns/show-check/opt-goods/opt-goods'
import optServe from './cpns/show-check/opt-serve/opt-serve'
import emitter from '@/utils/eventbus'
import { deepClone } from '@/utils/clone'
import { useRoute } from 'vue-router'
import { getServeCategoryList, getServeList2 } from '@/service/main/serve'

export default defineComponent({
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    }
  },
  components: {
    PageDialog,
    optGoods,
    optServe
  },
  emits: ['changeDialogVisible'],
  setup(props, { emit }) {
    const route = useRoute()
    const store = useStore()
    const ruleForm = reactive({
      name: ''
    })
    const changeDialogVisible = (flag) => {
      emit('changeDialogVisible', flag)
    }

    const currentPage = reactive({
      page: 1,
      pageSize: 10
    })

    const queryInfo = reactive({
      kind: "",
      keyword: ""
    })


    const handleSizeChange = (pageSize) => {
      currentPage.pageSize = pageSize
    }

    const handleCurrentChange = (page) => {
      currentPage.page = page
    }

    watch(currentPage, () => {
      initPage()
    }, {
      deep: true
    })

    const getServeFunc = async () => {
      const serveRes = await getServeList2({ ...queryInfo })
      let list = serveRes.data.servelist.filter(item=>{ return item.para})
      list.forEach(ele => {
        ele.para['serveId'] = ele.serveId
        ele['paraList'] = [ele.para]
      })
      serveList.value = list
    }

    const initPage = async () => {
      // store.dispatch('goods/getgoodsInfoListAction', {
      //   companyId: COMPANY_ID,
      //   page: currentPage.page,
      //   pageSize: currentPage.pageSize,
      //   keyword: "",
      //   status: 1
      // })

      getServeFunc()

      const res = await getServeCategoryList(COMPANY_ID)
      serveCategoryList.value = res.data.list


    }
    initPage()

    const serveList = ref([])
    const serveCategoryList = ref([])
    const serveCount = computed(() => store.state.serve.serveCount)
    const goodsInfoList = computed(() => store.state.goods.goodsInfoList)
    const goodsInfoCount = computed(() => store.state.goods.goodsInfoCount)
    const handleRemoveClick = (item) => {
    }

    const handleSaveClick = () => {
    }

    const checkGroupServeList = ref([])
    const checkGroupGoodsList = ref([])

    const handleServeClick = (item, type) => {
      if (type == 'serve') {
        currentOptServe.serveName = item.serveName
      } else {
        currentOptGoods.goodName = item.goodName
      }
    }

    const currentOptServe = reactive({
      serveId: '',
      flag: false,
      paraId: '',
      serveName: ''
    })

    const currentOptGoods = reactive({
      goodId: '',
      flag: false,
      paraId: '',
      goodName: ''
    })

    const handleCheckBoxChange = (value, type) => {
      if (type == 'serve') {
        currentOptServe.flag = value
      } else {
        currentOptGoods.flag = value
      }

    }

    const mainCheckHandselList = ref([])
    const handleCheckbox = (item, type) => {
      if (type == 'serve') {
        nextTick(() => {
          const index = mainCheckHandselList.value.findIndex(ele => ele.serveId == item.serveId)
          if (index == -1) {
            item.Qty = 1
            mainCheckHandselList.value.push({
              serveName: currentOptServe.serveName,
              serveId: item.serveId,
              paraIdList: [{ ...item, salePrice: (item.salePrice) / 100 }]
            })
          } else {
            if (currentOptServe.flag) {
              const indey = mainCheckHandselList.value[index].paraIdList.findIndex(ele => ele.paraId == item.paraId)
              if (indey == -1) {
                mainCheckHandselList.value[index].paraIdList.push({ ...item, salePrice: (item.salePrice) / 100 })
              }

            } else {
              let removeIndex = mainCheckHandselList.value[index].paraIdList.findIndex(val => val.paraId == item.paraId)

              mainCheckHandselList.value[index].paraIdList.splice(removeIndex, 1)
            }
          }
        })
      }
      else {
        nextTick(() => {
          const index = mainCheckHandselList.value.findIndex(ele => ele.goodId == item.goodId)
          if (index == -1) {
            item.Qty = 1
            mainCheckHandselList.value.push({
              goodName: currentOptGoods.goodName,
              goodId: item.goodId,
              paraIdList: [{ ...item, salePrice: (item.salePrice) / 100 }]
            })

          } else {
            if (currentOptGoods.flag) {
              const indey = mainCheckHandselList.value[index].paraIdList.findIndex(ele => ele.paraId == item.paraId)
              if (indey == -1) {
                item.Qty = 1
                mainCheckHandselList.value[index].paraIdList.push({ ...item, salePrice: (item.salePrice) / 100 })
              }

            } else {
              let removeIndex = mainCheckHandselList.value[index].paraIdList.findIndex(val => val.paraId == item.paraId)

              mainCheckHandselList.value[index].paraIdList.splice(removeIndex, 1)
            }

          }
        })
      }

    }

    const removeServeClick = (serveId, paraId) => {
      let index = mainCheckHandselList.value.findIndex(item => item.serveId == serveId)
      let removeIndex = mainCheckHandselList.value[index].paraIdList.findIndex(ele => ele.paraId == paraId)
      let removeCheckIndex = checkGroupServeList.value.findIndex(ele => ele == paraId)
      mainCheckHandselList.value[index].paraIdList.splice(removeIndex, 1)
      checkGroupServeList.value.splice(removeCheckIndex, 1)
    }

    const removeGoodsClick = (goodId, paraId) => {
      let index = mainCheckHandselList.value.findIndex(item => item.goodId == goodId)
      let removeIndex = mainCheckHandselList.value[index].paraIdList.findIndex(ele => ele.paraId == paraId)
      let removeCheckIndex = checkGroupServeList.value.findIndex(ele => ele == paraId)
      mainCheckHandselList.value[index].paraIdList.splice(removeIndex, 1)
      checkGroupGoodsList.value.splice(removeCheckIndex, 1)
    }

    emitter.on('refreshAddCardPageAddhandselDialog', (n) => {
      mainCheckHandselList.value = n
      checkGroupServeList.value = []
      checkGroupGoodsList.value = []
      let serveId = []
      let goodsId = []
      mainCheckHandselList.value.forEach(item => {
        let paraList = item.paraIdList
        paraList.forEach(ele => {
          if (item.serveId) {
            serveId.push(ele.paraId)
          } else {
            goodsId.push(ele.paraId)
          }
        })
      })

      checkGroupServeList.value = serveId
      checkGroupGoodsList.value = goodsId
    })

    watch(mainCheckHandselList, (n) => {
      emitter.emit('changeCurretnOptHandselList', [...n])
    }, {
      deep: true
    })

    const categoryChange = value => {
      getServeFunc()
    }

    const handleSearchClick = () => {
      getServeFunc()
    }

    watch(() => route.path, (n) => {
      if (n == '/main/card/add') {
        mainCheckHandselList.value = []
        checkGroupServeList.value = []
        checkGroupGoodsList.value = []
      }
    })

    onUnmounted(() => {
      emitter.off('refreshAddCardPageAddhandselDialog')
    })

    return {
      handleSearchClick,
      categoryChange,
      serveCategoryList,
      queryInfo,
      removeGoodsClick,
      mainCheckHandselList,
      checkGroupGoodsList,
      currentOptGoods,
      goodsInfoCount,
      goodsInfoList,
      currentOptServe,
      handleCheckbox,
      handleServeClick,
      checkGroupServeList,
      removeServeClick,
      serveCount,
      serveList,
      changeDialogVisible,
      ruleForm,
      COMPANY_ID,
      UPLOAD_BASE_URL,
      handleCheckBoxChange,
      handleRemoveClick,
      handleSaveClick,
      currentPage,
      handleSizeChange,
      handleCurrentChange,

    }

  }
})
</script>

<style scoped lang="less">
.add-staff-dialog {
  &:deep(.el-select) {
    width: 100%;
  }

  &:deep(.is-horizontal) {
    display: none !important;
  }

  .top-form {
    width: 50%;
    display: flex;

    &:deep(.el-button) {
      margin-left: 10px;
    }

    &:deep(.el-select) {
      margin-right: 10px;
    }

    .search {
      display: flex;
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }

  .content {
    display: flex;
    border-top: 1px solid var(--borderColor);
    padding-top: 20px;
    height: 410px;
    overflow: hidden;

    .left-item {
      flex: 1;

      .titls {
        display: flex;
        margin-bottom: 20px;
        padding: 0 10px;
        background-color: rgb(239, 239, 239);
        font-size: 12px;

        div {
          flex: 1;
          line-height: 40px;
        }
      }

      .goods-list {
        .left,
        .right {
          flex: 1 !important;
        }
      }

      .staff-list {
        margin-top: 20px;

        .serve-list {
          padding: 0 10px;

          .item {
            display: flex;
            font-size: 12px;

            &:deep(.el-checkbox) {
              display: inline-block;
              width: 100% !important;

              .el-checkbox__label {
                display: inline-block;
                width: 100%;
              }
            }

            .left {
              flex: 1;
            }

            .right {
              flex: 2;

              .checkbox {
                display: flex;

                div {
                  flex: 1;
                }

                .salePrice {
                  color: rgb(255, 105, 74);
                }

                .serveLength {
                  position: relative;
                  left: -8px;
                }
              }
            }
          }
        }

        &:deep(.el-checkbox-group) {
          .el-checkbox-button {
            width: 100%;
            margin-bottom: 10px;

            span {
              width: 100%;
            }
          }
        }
      }
    }

    .right-item {
      margin-left: 10px;
      padding-left: 10px;
      flex: 1;
      border-left: 1px solid var(--borderColor);
      font-size: 12px;
      // margin-top: 56px;

      .title {
        padding: 0 10px;
        display: flex;
        background-color: rgb(239, 239, 239);

        .num {
          flex: 2;
        }

        div {
          flex: 1;
          padding-left: 4px;
          line-height: 40px;
        }
      }
    }
  }
}
</style>
