<template>
  <div class="add-form">
    <el-tabs v-model="activeName" class="tabs" @tab-click="handleClick">
      <el-tab-pane name="baseInfo" disabled label="第一步：编辑基本信息">
        <base-info></base-info>
      </el-tab-pane>
      <el-tab-pane name="editDetail" disabled label="第二步：编辑卡详情">
        <card-detail></card-detail>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref,onUnmounted } from 'vue'
import BaseInfo from './cpns/base-info/base-info.vue'
import CardDetail from './cpns/card-detail/card-detail.vue'
import emitter from '@/utils/eventbus'
export default defineComponent({
  props: {

  },
  components: {
    BaseInfo,
    CardDetail
  },
  setup() {
    const activeName = ref('baseInfo')
    emitter.on('changeAddCardNextClick', () => {
      activeName.value = 'editDetail'
    })

    emitter.on('changeAddCardPreviousClick', () => {
      activeName.value = 'baseInfo'
    })

    onUnmounted(()=>{
      emitter.off('changeAddCardNextClick')
      emitter.off('changeAddCardPreviousClick')
    })


    return {
      activeName
    }

  }
})
</script>

<style scoped lang="less">
.add-form {

}
</style>




